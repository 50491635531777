.slider{
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
    overflow: hidden;
    font-size: 42px;
    padding-bottom: 76px;
}

.slider-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;
    transform: translate3d(20.2%, 0px, 0px);
    transition-duration: 300ms;
}

.card-slider{
    height: 218px;
    width: 372px;
    background-color: gray;
    border-radius: 20px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,.30);
    overflow: hidden;
    transition: 0.2s ease-out;
    position: relative;
}

.card-slider:hover {
    transition: 0.2s ease-out;
    transform: scale(1.1);

}

.image-card {
    border-radius: 20px;
    /* object-fit: contain; */
    /* box-sizing: border-box; */
    width: 100%;
}

.prev-button{
    position: absolute;
    left: 0;
    border-radius: 0 100px 100px 0;
    z-index: 1;
    background-image: url(../images/icones/arrow_left.svg);
    background-repeat: no-repeat;
    background-position: 40%;
    background-color: #fff;
    height: 80px;
    width: 80px;
    background-size: 20px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,.30);
    transition: 0.2s ease-out;
}

.prev-button:hover {
    transform: scale(1.1);
}

.next-button{
    position: absolute;
    right: 0;
    border-radius: 100px 0 0 100px;
    background-image: url(../images/icones/arrow_right.svg);
    background-repeat: no-repeat;
    background-position: 60%;
    background-color: #fff;
    height: 80px;
    width: 80px;
    background-size: 20px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,.30);
    transition: 0.2s ease-out;
}

.next-button:hover {
    transform: scale(1.1);
}

.btn-minia {
    z-index: 10;
    position: absolute;
    left: 0;
    height: 100%;
    width: inherit;
    opacity: 0;
}

.btn-minia:hover {
    opacity: 0.5;
}

.swiper{
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
}


@media (min-width: 2000px){
    .next-button{
      right: 10%;
    }
    .prev-button{
      left: 10%;
    }
  }
  
  @media (min-width: 2850px){
    .next-button{
      right: 30%;
    }
    .prev-button{
      left: 30%;
    }
  }