.nav-liens {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 24px;
    z-index: 100;
    max-width: calc(100% - 32px);
    width: 1180px;
    height: 83px;
    position: absolute;
    background-color: #fff;
    padding: 16px 32px;
    padding-left: 16px;
    top: 24px;
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;

}

html{scroll-behavior:smooth}

.nav-liens{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

a{
    text-decoration: none;
    color: #292929;
    font-weight: 600;
    line-height: 1.2;
    font-size: 20px;
    font-family: 'Red Hat Display';
    text-align: center;
}

a:hover{
    color: gray;
}

.menu-burger-nav {
    position: fixed;
    top: 0px;
    z-index: 999;
    display: none;
    transition: 0.2s ease-out;
    left: -230px;
}

.menu-burger-nav.active{
    left: 0;
}

.menu-burger-nav button{
    border-radius: 0 20px 20px 0;
    top: 20px;
    position: relative;
}

.menu-burger-nav img {
    height: 100%;
}

.menu-nav-list{
    display: flex;
    flex-direction: column;
    /* display: none; */
    /* width: 0; */
    background: lightgrey;
    height: 100vh;
    gap: 50px;
    padding: 50px;
}


@media (max-width: 767px){
    .nav-liens{
        visibility: hidden;
        overflow: hidden;
    }
    .menu-burger-nav{
        display: flex;
        flex-direction: row-reverse;
    }
    section h2{
        font-size: 37px;
    }
    section p{
        font-size: 20px;
    }
    .propos {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 768px){
    
}