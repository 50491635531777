.bck-cookies {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 43%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.box-cookies{
    background: lightgray;
    border-radius: 25px;
    padding: 25px;

}

.cookies-btns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.box-cookies button {
    margin: 0;
    height: initial;
    border: 2px solid black;
}



@media (min-width: 2000px)
{.bck-cookies {
    /* margin-right: 30%; */
    margin-left: -10%;
}}

@media (min-width: 2850px)
{.bck-cookies {
    /* margin-right: 30%; */
    margin-left: -30%;
}}

