@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Montserrat&family=Red+Hat+Display:wght@500&display=swap');
/* font-family: 'Red Hat Display', sans-serif; */
@font-face {
	font-family: 'Red Hat Display';
	src: url('https://fonts.googleapis.com/css2?family=Manrope&family=Montserrat&family=Red+Hat+Display:wght@500&display=swap')  format('truetype');
  }

.page-hero{
    padding-top: 160px;
    padding-bottom: 120px;
    color: #fff;
    position: relative;
    overflow: hidden;
    /* min-height: 100vh; */
    /* max-height: 840px; */
    height: -webkit-fit-content; 
    height: -moz-fit-content;
    height: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'Red Hat Display';
    display: flex;
}

.page-hero h2{
    color: #fff;
}

.page-video #hero-video{
position: absolute;
height: 100%;
object-fit: cover;
width: 100%;
object-position: center;
top: 0;
background-color: #ff0800;
}

.text-video{
    display: flex;
    flex-direction: column;
    /* margin-left: 100px; */
    margin-left: 0;
    z-index: 10;
    width: 100%;
    align-items: center;
}

@media screen and (max-width: 1440px){
.page-hero {
    height: 300px;
}
}

@media (max-width: 1023px){
h1 {
    font-size: calc(1.9184652278vw + 32.3741007194px);
}
.text-video{
    margin-left: 0;
    align-items: center;
    width: 100%;
}
.bloc-btn div {
    margin: 10px;
}
.page-hero{
    align-items: center;
}
}


@media (min-width: 767px){
h1 {
    font-size: calc(1.5625vw + 36.015625px);
}}
h1 {
    font-size: 48px;
    font-weight: 900;
    margin-bottom: 16px;
}

.bloc-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 50px;
    margin-top: 100px;

}

.btn-contacter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 22px;
    padding: 0 12px 0 12px;
    
}

.btn-contacter a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: 0.2s ease-out

}

.btn-github {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 22px;
    padding: 0 12px 0 12px;
    
}

.btn-github a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.2s ease-out
}

.bloc-btn a:hover{
    transition: 0.2s ease-out;
    transform: scale(1.1);
}



button{
    border: none;
    height: 67px;
    border-radius: 20px;
    color: black;
    font-size: 20px;
}

.footer{
    width: 100%;
    background-color: black;
    height: 280px;
    margin-top: 35px;
}