h2{
    color: #292929;
}

.technologies{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
    background: gainsboro;
    font-size: 42px;
}

.tech-icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

}
