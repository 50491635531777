.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    /* height: 150px; */
    /* background-image: url(https://www.kuuasema.com/wp-content/themes/kuuasema-theme-v2-2-0/dist/images/test.svg); */
    /* transform: none; */
    /* background-color: #292929; */
    /* background-color: black;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    padding-bottom: 30px;
    margin: 0 10px 0 10px;
    font-size: 42px; */

    background: linear-gradient(132deg, #53242a, #5d5c5f, #212335);
    background-size: 300% 300%;
    animation: Gradient 8s ease infinite;
    /* position: absolute; */
    /* min-height: 100vh; */
    width: 100%;
    overflow: hidden;
    padding:0;
    margin:0px;
    height: 100%;
    font-size: 42px;
    padding-top: 25px;
    padding-bottom: 25px;
}

@keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff86;
    padding: 0 50px 50px 50px;
    opacity: 0.8;
    border-radius: 20px;
    width: 70%;

}

.contact h2{
    color: white;
    margin-bottom: 50px;
}

form{
    display: flex;
    flex-direction: column;
    width: 40%;
}

label {
    color: #FFF;
    font-size: 25px;
    margin: 5px;
}

input {
    padding-left: 12px;
}

input, textarea {
    height: 46px;
    /* width: 40%; */
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px;
    border: none;
    outline: 0;
    border-radius: 6px;
    margin-bottom: 12px;
    transition: box-shadow .3s;
    
}

input:hover{
    border-color: blueviolet;
}

textarea {
    resize: none;
    height: 150px;
    padding: 12px;
}

.check-conditions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    border-radius: 6px;
    background: white;
    padding: 15px;
}

.btn-envoyer{
    width: inherit;
    margin-left: auto;
    margin-right: auto;
}

#checkbox{
    margin: 0;
}

.check-conditions p {
    font-size: 15px;
}

.modale-conditions{
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 43%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact .pure-modal-backdrop h3{
    font-size: 15px;
}

.contact .pure-modal-backdrop li{
    font-size: 15px;
    
}
li{
    padding-left: 15px;
}


@media (max-width: 1300px){
    form{
        width: 100%;
    }

}

@media (max-width: 767px){
    form{
        width: 100%;
    }
}