.propos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px;
    font-size: 25px;
    text-align: center;
}

.propos p {
    color: #666;
}

@media (max-width: 750px){
    .propos {
        margin: 50px;
    }
    .propos p {
        font-size: 15px;
    }
}